import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormSelect } from '@/components/form-select/FormSelect';

import useFetch from '@/hooks/useFetch';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DEFAULT_MAX_ITEM_ALLOW, TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { IParams } from '@/utils/interfaces/service';
import { CATEGORY_OPTIONS } from '@utils/constants';

import { ITaskMaster } from '../models';
import { ITitleQueryFilterParams, generateFilter } from '../utils';

export default function FormGroupTitleCategoryProcessOfTask() {
  const { idProject } = useParams();
  const { t } = useTranslation();
  const form = useFormContext();
  const { data: resProcess } = useFetch<any>(`/prj/projectprocess/${idProject}`, 'GET');
  const { otherCountry } = useOptionsGlobal();
  const [paramsGetTaskMaster, setParamsGetTaskMaster] = useState<IParams>({
    pageIndex: 0,
    pageSize: DEFAULT_MAX_ITEM_ALLOW
  });
  const [optionsTaskMaster, setOptionsTaskMaster] = useState<ITaskMaster[]>([]);
  const { data: resTaskMaster } = useFetch<any>('/mst/tasks/search', 'POST', paramsGetTaskMaster);
  useEffect(() => {
    const formValues: ITitleQueryFilterParams = {
      category: form.getValues('category') || '',
      countryIds: [otherCountry?.id || '', form.getValues('countryId') || ''],
      projectIds: [idProject || '', '']
    };
    setParamsGetTaskMaster({ ...paramsGetTaskMaster, filter: generateFilter(formValues) });
  }, [form.watch('category'), form.watch('countryId')]);

  useEffect(() => {
    setOptionsTaskMaster(
      resTaskMaster?.data?.map((item: any) => ({
        ...item,
        label: item.managementCode,
        value: item.id
      }))
    );
  }, [resTaskMaster]);

  const clearDataForm = () => {
    form.setValue('projectTaskAttachmentFiles', []);
    form.setValue('taskRequireDocuments', []);
    form.setValue('taskInternalDocuments', []);
  };
  return (
    <div className='border border-solid border-gray2 rounded-lg p-4 bg-white'>
      <Row gutter={16}>
        <Col span={12}>
          <FormSelect
            name='processId'
            required
            label={String(t('project:filter:process:label'))}
            placeholder={String(t('project:add:please_select_field', { field: t('project:filter:process:label') }))}
            options={resProcess?.map((item: any) => ({ label: item.processName, value: item.id })) || []}
          />
        </Col>
        <Col span={12}>
          <FormSelect
            name='category'
            required
            label={String(t('project:template_layout:category'))}
            placeholder={String(t('project:add:please_select_field', { field: t('project:template_layout:category') }))}
            options={CATEGORY_OPTIONS}
            handleChange={() => {
              form.setValue('taskMasterId', null);
              form.setValue('detail', null);
              clearDataForm();
            }}
          />
        </Col>
      </Row>
      <Row gutter={16} className='mt-4'>
        <Col span={24}>
          <FormSelect
            maxLengthInputSearch={TEXT_FIELD_MAX_LENGTH}
            disabled={!form.watch('category')}
            optionLabelProp='name'
            addOptionKeys={['name']}
            options={optionsTaskMaster}
            name='taskMasterId'
            required
            label={String(t('project:template_layout:title'))}
            placeholder={String(t('project:add:please_select_field', { field: t('project:template_layout:title') }))}
            allowAddOnSearch
            addRequest={{
              mappingParams(inputValue) {
                return {
                  name: inputValue,
                  projectId: idProject,
                  type: form.getValues('category'),
                  managementCode: inputValue,
                  countryId: form.getValues('countryId'),
                  content: ''
                };
              },
              method: 'POST',
              apiEndPoint: `/mst/tasks`,
              defaultParams: {}
            }}
            handleChange={(value) => {
              const task = optionsTaskMaster?.find((item: ITaskMaster) => item.id === value);
              form.setValue('detail', task?.content ?? '');
              clearDataForm();
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
