import { useAppDispatch } from '@/hooks';
import { ColumnsType } from 'antd/es/table';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import FormBaseFilter, { RefActionControl } from '@/components/base-filter';
import TableFetch from '@/components/common/table-fetch';
import { TagTaskType } from '@/components/common/task/TagTaskType';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, * as s from '@/hooks/useAuthorization';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMutation from '@/hooks/useMutation';

import { CATEGORY_OPTIONS, ROUTER_IDS } from '@/utils/constants';
import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime, handleLinkClick, renderSpecialText, sanitizeAndRemoveInsecureLinks } from '@/utils/helpers/globalHelper';

import IconAddNew from '../../../assets/icons/base-filter/icon-add-new.svg';
import AppTooltip from '../../../components/app-tooltip/AppTooltip';
import { BaseButton } from '../../../components/base-button/BaseButton';
import useOptionsGlobal from '../../../hooks/useOptionsGlobal';
import { FORMAT_DATE_EN_HH_MM_SS, TYPE } from '../../../utils/constants/AppConstants';
import { Ii18n } from '../../../utils/interfaces/i18n';
import { ITaskMasterData } from '../../../utils/interfaces/masterdata';
import { AddEditTaskMaster } from './AddEditTaskMaster';
import FormFilter from './Filter';
import { DEFAULT_FILTER_SEARCH_FORM, generateFilter } from './utils';
import { IFilterSearchForm, IFilters, IQueryFilterParams } from './utils/interface';

import './AddEditTaskList.scss';

function MasterManagement() {
  const { t }: Ii18n = useTranslation();
  const { isInternalUser, hasPermission } = useAuthorization();
  const refAddEditModal = useRef<any>(null);
  const filterTableRef = useRef<RefActionControl>({
    toggle: () => {}
  });
  const { data, update: updateFilterConfigs } = useFilterConfigs<IFilterSearchForm>(ROUTER_IDS.TASK_MASTER_LIST);
  const { countryOptions } = useOptionsGlobal();
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<any>(null);

  const { mutate: deleteMultipleTasks } = useMutation(`/mst/tasks`, {
    method: 'DELETE',
    showToastError: true,
    paramMsg: {
      field: '',
      item: '',
      reason: t('master_data:error_msg:task_in_used')
    }
  });

  const getDefaultFilters = () => {
    const results = {
      form: cloneDeep(DEFAULT_FILTER_SEARCH_FORM),
      query: {}
    };
    if (data && !isEmpty(data)) {
      results.form.primary = { ...data.primary };
      const { updatedDateFrom, updatedDateTo, countryId, category } = data.primary ?? {};
      results.query = {
        filter: generateFilter({
          keyword: '',
          projectId: '',
          updatedDateFrom,
          updatedDateTo,
          countryId,
          category
        })
      };
    }
    return results;
  };
  const [filter, setFilter] = useState<IFilters>(getDefaultFilters().query);
  const formFilter = useForm<IFilterSearchForm>({
    defaultValues: getDefaultFilters().form
  });

  const columns: ColumnsType<ITaskMasterData> = [
    {
      key: 'type',
      dataIndex: 'type',
      title: t('master_data:task:category'),
      width: 90,
      render(_, record) {
        return <TagTaskType type={record.type} className='!mr-0' />;
      }
    },
    {
      key: 'managementCode',
      dataIndex: 'managementCode',
      title: t('master_data:task:management_name'),
      width: 200,
      render(text, record) {
        return (
          <Link
            className={'link-custom text-overflow-1-line-clamp' + (hasPermission(s.TASK_LIST_BUTTON_CREATE, s.C) ? '' : ' disabled')}
            onClick={() => refAddEditModal?.current?.handleOpenEditModal(record)}
            to={''}
          >
            <AppTooltip className='block max-w-full truncate w-fit' title={renderSpecialText(text)}>
              {renderSpecialText(text)}
            </AppTooltip>
          </Link>
        );
      }
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('master_data:task:display_name'),
      width: 200,
      render(text) {
        return (
          <AppTooltip className='block max-w-full w-fit whitespace-pre-wrap break-all text-overflow-3-line-clamp' title={text}>
            {text}
          </AppTooltip>
        );
      }
    },
    {
      key: 'content',
      dataIndex: 'content',
      title: t('master_data:task:task_content'),
      render(text) {
        return (
          <div className='inline-block remark'>
            <AppTooltip
              title={
                <span
                  className='tooltip-title task-remark-tooltip'
                  dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text?.replace(/<p>(.*?)<\/p>/, '$1') as string) || '') }}
                />
              }
            >
              <span
                className='whitespace-pre-wrap raw-data break-all text-overflow-3-line-clamp'
                dangerouslySetInnerHTML={{ __html: sanitizeAndRemoveInsecureLinks((text as string) || '') }}
                onClick={(e) => handleLinkClick(e)}
              ></span>
            </AppTooltip>
          </div>
        );
      }
    },
    {
      key: 'countryName',
      dataIndex: 'countryName',
      title: t('master_data:task:country_name') || '',
      width: 160,
      render: (_: any, record) => (
        <AppTooltip title={record?.countryName ?? record?.countryName ?? ''}>{record?.countryName ?? record?.countryName ?? ''}</AppTooltip>
      )
    },
    {
      key: 'updatedName',
      dataIndex: 'updatedName',
      title: t('master_data:updater'),
      width: 140,
      render: (_: any, record) => (
        <AppTooltip
          title={record?.updatedName ?? record?.createdName ?? ''}
          className='block max-w-full w-fit whitespace-pre-wrap break-all text-overflow-3-line-clamp'
        >
          {record?.updatedName ?? record?.createdName ?? ''}
        </AppTooltip>
      )
    },
    {
      key: 'updatedDate',
      dataIndex: 'updatedDate',
      title: t('master_data:updated_date'),
      sorter: true,
      width: 170,
      render(_: any, record) {
        return (
          <span>
            <AppTooltip title={DataViewer.localTimeBy(record?.updatedDate ?? record?.createdDate, FORMAT_DATE_EN_HH_MM_SS, '')}>
              {DataViewer.localTimeBy(record?.updatedDate ?? record?.createdDate, FORMAT_DATE_EN_HH_MM_SS, '')}
            </AppTooltip>
          </span>
        );
      }
    }
  ];

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const idsToDelete = records.map((item) => ({
      id: item.id || '',
      version: item.version || ''
    }));

    if (!idsToDelete.length) {
      return 0;
    }
    const result = await deleteMultipleTasks({
      ids: idsToDelete
    });
    if (!result) return 0;
    const { data } = result;

    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('master_data:phrase:delete') })
        })
      );
    }

    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };

  const updateFilter = (values: IFilterSearchForm) => {
    const { keyword, primary } = values ?? {};
    const { updatedDateFrom, updatedDateTo } = primary ?? {};
    const formValues: IQueryFilterParams = {
      keyword,
      projectId: '',
      category: values?.primary?.category,
      countryId: values?.primary?.countryId,
      updatedDateFrom,
      updatedDateTo
    };
    setFilter({ filter: generateFilter(formValues) });
    updateFilterConfigs(values);
  };

  const generateFilterHeader = () => {
    return (
      <div className='mb-[12px]'>
        <FormProvider {...formFilter}>
          <FormBaseFilter
            onSubmit={(values) => {
              updateFilter(values as IFilterSearchForm);
            }}
            searchBox={{ name: 'keyword', placeholder: DataViewer.display(t(`master_data:task:search_placeholder`)) }}
            tagSection={{
              renderTag: (fieldKey, fieldValue) => {
                if (fieldKey === 'category') {
                  const item = CATEGORY_OPTIONS.find((item: any) => item.value === fieldValue);
                  return <>{t(`${item?.label}`)}</>;
                }
                if (fieldKey === 'countryId') {
                  return <>{countryOptions?.find((i) => i.id === fieldValue)?.label ?? fieldValue}</>;
                }
                if (['updatedDateFrom', 'updatedDateTo'].includes(fieldKey)) {
                  return <>{formatDateTime(fieldValue)}</>;
                }
                return <>{fieldValue}</>;
              }
            }}
            primaryAction={{
              label: 'button:filter',
              name: 'primary',
              popoverContent: (
                <FormFilter
                  onSubmit={(values) => {
                    formFilter.reset((previous) => ({ ...previous, primary: { ...(values as any) } }));
                    formFilter.handleSubmit(updateFilter)();
                  }}
                  defaultValues={formFilter.getValues('primary')}
                />
              ),
              refControl: filterTableRef
            }}
            secondaryAction={null}
          />
        </FormProvider>
      </div>
    );
  };

  return (
    <div className='container-master-data' ref={wrapperRef}>
      <AddEditTaskMaster
        ref={refAddEditModal}
        wrapperRef={wrapperRef}
        setFilter={() => {
          setFilter({ ...filter });
        }}
      />
      <div className='page-list-header'>
        <span className='title-24'>{t('master_data:title_task_list')}</span>
        {hasPermission(s.TASK_LIST_BUTTON_CREATE, s.C) && (
          <BaseButton size='medium' icon={<IconAddNew style={{ verticalAlign: 'sub' }} />} onClick={refAddEditModal?.current?.handleOpenCreateModal}>
            {t('master_data:create_new')}
          </BaseButton>
        )}
      </div>
      <div className='data-table mt-[12px]'>
        <TableFetch
          tableHeader={generateFilterHeader()}
          apiEndpoint='/mst/tasks/search'
          apiMethod='POST'
          columns={columns}
          filterDefault={filter}
          scrollTable={{ maxHeight: '100vh - 248px' }}
          showDelete={hasPermission(s.DELETE_TASK_LIST, s.V)}
          showDownload={false}
          showAddLine={false}
          handleDeleteClick={hasPermission(s.DELETE_TASK_LIST, s.C) ? handleDelete : undefined}
          deleteConfirmPopup={{
            type: 'discard',
            title: t('common:MSG_C_012:title', { field: t('master_data:task:title') }) ?? '',
            msg: t('common:MSG_C_012:description', { field: t('master_data:task:title') }) ?? ''
          }}
          emptyDataWithoutFilter={
            <EmptyDataWithIcon
              title={{
                content: 'master_data:phrase:create_new_label'
              }}
              description={{
                content: 'master_data:phrase:no_data_description'
              }}
              button={{
                label: 'master_data:phrase:create',
                props: {
                  onClick: refAddEditModal?.current?.handleOpenCreateModal
                }
              }}
            />
          }
          rowSelection={isInternalUser ? null : {}}
        />
      </div>
    </div>
  );
}

export default MasterManagement;
