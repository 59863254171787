import classNames from 'classnames';

import { STATUS_NAME, StatusName } from '@/pages/project-management/detail/constant';

import { formatDateTime, getMaxByKey, getMinByKey, groupByKey } from '@/utils/helpers/globalHelper';
import { CATEGORY } from '@utils/constants';

import CompletedIcon from '@/assets/icons/CompletedIcon.svg';
import InprogressIcon from '@/assets/icons/InprogressIcon.svg';
import TodoIcon from '@/assets/icons/TodoIcon.svg';
import PendingIcon from '@/assets/icons/WarningWaitingApprove.svg';

import { ITask } from '.';

const TagTask = (props: { icon: React.ReactElement; color: string; bg: string; status: string; className?: string; hiddenIcon?: boolean }) => {
  const { icon, color, bg, status, className, hiddenIcon = false } = props;

  return (
    <div
      className={classNames(
        'w-[80px] flex justify-center items-center gap-[4px] text-[12px] font-medium border-[1px] border-solid rounded-[4px]',
        className ?? ''
      )}
      style={{ backgroundColor: bg, color, borderColor: color }}
    >
      {!hiddenIcon && icon}
      {status}
    </div>
  );
};

export const renderTaskStatus = (statusName?: StatusName, className?: string, hiddenIcon?: boolean) => {
  if (!statusName) return '';
  const mappingIcon: { [key: string]: React.ReactElement } = {
    [STATUS_NAME.TODO]: (
      <TagTask icon={<TodoIcon />} color='#9E9FAC' bg='#F5F5F7' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.DELAYED]: (
      <TagTask icon={<PendingIcon />} color='#F2A227' bg='#FEF6E9' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.IN_PROGRESS]: (
      <TagTask icon={<InprogressIcon />} color='#076CE3' bg='#EEF5FD' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.COMPLETED]: (
      <TagTask icon={<CompletedIcon />} color='#36B368' bg='#E7F1EF' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.PENDING_VERIFICATION]: (
      <TagTask icon={<PendingIcon />} color='#F2A227' bg='#FEF6E9' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.SUBMITTED]: (
      <TagTask icon={<CompletedIcon />} color='#36B368' bg='#E7F1EF' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.NOT_SUBMITTED]: (
      <TagTask icon={<TodoIcon />} color='#9E9FAC' bg='#F5F5F7' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    ),
    [STATUS_NAME.DOUBLE_CHECK]: (
      <TagTask
        icon={<InprogressIcon />}
        color='#076CE3'
        bg='#EEF5FD'
        status={statusName}
        className={classNames('!w-auto max-w-[122px] px-[8px]', className)}
        hiddenIcon={hiddenIcon}
      />
    ),
    [STATUS_NAME.PENDING]: (
      <TagTask icon={<InprogressIcon />} color='#076CE3' bg='#EEF5FD' status={statusName} className={className} hiddenIcon={hiddenIcon} />
    )
  };

  return mappingIcon[statusName];
};

export const renderDataTask = (listTask: ITask[]) => {
  const groups = groupByKey(listTask, 'taskType');

  const listRenderDate = listTask.filter((i) => [CATEGORY.MOT.toString(), CATEGORY.OTHER.toString()].includes(i.taskType));
  const isAllTaskCompleted = listRenderDate.every(isComplete);

  const maxDeadlineDate = getMaxByKey(listRenderDate, 'deadline')?.deadline;
  const minStartDate = getMinByKey(listRenderDate, 'inprogressDate')?.inprogressDate;
  const maxCompletedDate = isAllTaskCompleted ? getMaxByKey(listRenderDate, 'completeDate')?.completeDate : '';

  return {
    groups,
    maxDeadlineDate: formatDateTime(maxDeadlineDate ?? ''),
    minStartDate: formatDateTime(minStartDate ?? ''),
    maxCompletedDate: formatDateTime(maxCompletedDate ?? ''),
    status: getStatusTask(listTask)
  };
};

export const isTodo = (i: ITask) => i.statusName === STATUS_NAME.TODO;
export const isComplete = (i: ITask) => i.statusName === STATUS_NAME.COMPLETED;
export const isPending = (i: ITask) => i.statusName === STATUS_NAME.DELAYED;

export const getStatusTask = (listTask: ITask[], isCheckAllTaskType = false) => {
  const listCheckStatus = isCheckAllTaskType
    ? listTask
    : listTask.filter((i) => [CATEGORY.MOT.toString(), CATEGORY.OTHER.toString()].includes(i.taskType));

  const isDelay = listCheckStatus.length && listCheckStatus.some(isPending);
  if (isDelay) {
    return STATUS_NAME.DELAYED;
  }

  const isNotStart = listCheckStatus.length && listCheckStatus.every(isTodo);
  if (isNotStart) {
    return STATUS_NAME.TODO;
  }

  const isCompleted = listCheckStatus.length && listCheckStatus.every(isComplete);
  if (isCompleted) {
    return STATUS_NAME.COMPLETED;
  }

  const inProgress = listCheckStatus.length && !isNotStart && !isCompleted && !isDelay;
  if (inProgress) {
    return STATUS_NAME.IN_PROGRESS;
  }

  return '';
};
