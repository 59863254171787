import { IDataDetail } from '../utils';

export const DEFAULT_DETAIL_PROJECT: IDataDetail = {
  id: null,
  code: null,
  version: null,
  projectInfor: null,
  templateId: null,
  countryId: null,
  projectProcesses: null,
  statusName: null,
  active: true,
  templateName: null,
  picId: null,
  isPublished: true
};

export const DEFAULT_PAGE = 0;

export const STATUS_NAME = {
  TODO: '開始前',
  IN_PROGRESS: '進行中',
  DELAYED: '遅延',
  COMPLETED: '完了',
  CANCELLED: '中止',
  PENDING_VERIFICATION: '確認待ち',
  SUBMITTED: '提出済',
  NOT_SUBMITTED: '未提出',
  DOUBLE_CHECK: 'ダブルチェック',
  PENDING: '申請中'
} as const;
export type StatusName = (typeof STATUS_NAME)[keyof typeof STATUS_NAME];

export const FILTER_TEMPLATE = {
  TEMPLATE: 'template',
  FILTER: 'filter',
  NONE: 'none'
} as const;
