import { Button, PopoverProps, Tag } from 'antd';
import classNames from 'classnames';
import { keys } from 'lodash';
import React, { isValidElement, useMemo, useRef } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ErrorBoundary from '@/components/error-boundary';

import { DataViewer } from '@/utils/helpers/common';
import { ISelectOption } from '@/utils/interfaces/form';

import BaseAction, { SecondaryActionDefault } from './BaseAction';
import ErrorFallback from './ErrorFallback';
import SearchBox from './SearchBox';

import './Styles.scss';

export type RefActionControl = {
  toggle: (value: boolean) => void;
};
type SearchBoxSection = {
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  name?: string;
} | null;

type PrimaryActSection = {
  label: string;
  popoverContent: React.ReactNode | React.ReactElement<any>;
  disabled?: boolean;
  name?: string;
  totalFiltered?: number;
  refControl?: React.MutableRefObject<RefActionControl>;
  getPopupContainer?: PopoverProps['getPopupContainer'];
} | null;

type SecondaryActSection = {
  label: string;
  options: ISelectOption[];
  disabled?: boolean;
  name?: string;
  popoverContent?: React.ReactNode | React.ReactElement<any>;
  refControl?: React.MutableRefObject<RefActionControl>;
  getPopupContainer?: PopoverProps['getPopupContainer'];
} | null;

type TagSection = {
  renderTag?: (fieldKey: string, fieldValue: any) => React.ReactNode | React.ReactElement<any>;
  onClear?: (field: { [key: string]: any } | null) => void;
} | null;

export interface IFormBaseFilterProps {
  searchBox?: SearchBoxSection | React.ReactNode | React.ReactElement<any>;
  primaryAction?: PrimaryActSection | React.ReactNode | React.ReactElement<any>;
  secondaryAction?: SecondaryActSection | React.ReactNode | React.ReactElement<any>;
  tagSection?: TagSection | React.ReactNode | React.ReactElement<any>;
  defaultOrderTagSection?: string[];
  onSubmit?: (values: FieldValues) => void;
  loading?: boolean;
}

const Filter = (props: IFormBaseFilterProps) => {
  const { t } = useTranslation();
  const { searchBox, primaryAction, secondaryAction, tagSection, onSubmit, loading } = props;
  const methods = useFormContext();
  const primaryRef = useRef<any>(null);
  const primaryFieldName = primaryAction !== null && !isValidElement(primaryAction) ? (primaryAction as PrimaryActSection)?.name ?? '' : '';
  const watchPrimaryFields = primaryAction !== null && !isValidElement(primaryAction) ? methods.watch(primaryFieldName) ?? {} : {};

  const defaultOrderDict = useMemo(() => {
    return (props.defaultOrderTagSection ?? []).reduce((orderDict: Record<string, number>, inputName: string, index) => {
      orderDict[inputName] = index;
      return orderDict;
    }, {});
  }, [props.defaultOrderTagSection]);

  const generateSearchBoxSection = () => {
    if (searchBox === null) return <></>;
    if (isValidElement(searchBox)) return <div className='search-box-wrapper'>{searchBox}</div>;
    const { name = 'search' } = (searchBox as SearchBoxSection) ?? {};
    return (
      <div className='search-box-wrapper'>
        <SearchBox
          control={methods.control}
          name={name}
          onSubmit={methods.handleSubmit(onSubmit)}
          maxLength={128}
          {...(searchBox as SearchBoxSection)}
        />
      </div>
    );
  };

  const calculateTotalPrimaryFilters = (defaultVal: number | undefined): number | undefined => {
    if (typeof defaultVal === 'number' && defaultVal === 0) return undefined;
    if (typeof defaultVal === 'number') return defaultVal;
    if (tagSection === null || !primaryFieldName || isValidElement(primaryFieldName)) {
      console.warn('%c Calculate must required primaryAction as object and tagSection', 'font-size:14px;color:red');
      return;
    }
    const fieldKeyHasValue = keys(watchPrimaryFields).filter((key) => !DataViewer.isEmpty(watchPrimaryFields[key]));
    return fieldKeyHasValue?.length > 0 ? fieldKeyHasValue.length : undefined;
  };

  const generateActionGroup = () => {
    if (primaryAction === null && secondaryAction === null) return <></>;
    if (isValidElement(primaryAction)) {
      return <div className='flex justify-center items-center'>{primaryAction}</div>;
    }
    const { label: primaryLabel = 'button:filter', popoverContent: primaryPopover, totalFiltered } = (primaryAction as PrimaryActSection) ?? {};
    const {
      label: secondaryLabel = 'project:second_filter:my_project',
      popoverContent: secondaryPopover = (
        <SecondaryActionDefault
          name={(secondaryAction as SecondaryActSection)?.name ?? 'secondaryFilters'}
          options={(secondaryAction as SecondaryActSection)?.options ?? []}
          {...(secondaryAction as SecondaryActSection)}
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      )
    } = (secondaryAction as PrimaryActSection) ?? {};
    return (
      <div className='flex justify-center items-center gap-[16px]'>
        {secondaryAction !== null && (
          <div>
            {isValidElement(secondaryAction) ? (
              secondaryAction
            ) : (
              <BaseAction label={secondaryLabel} popoverContent={secondaryPopover} {...(secondaryAction as SecondaryActSection)} />
            )}
          </div>
        )}
        {primaryAction !== null && (
          <div ref={primaryRef}>
            {isValidElement(primaryAction) ? (
              primaryAction
            ) : (
              <BaseAction
                label={primaryLabel}
                popoverContent={primaryPopover}
                {...(primaryAction as PrimaryActSection)}
                totalFiltered={calculateTotalPrimaryFilters(totalFiltered)}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const generateFilterTags = () => {
    if (tagSection === null) return <></>;
    if (isValidElement(tagSection)) {
      return <div className='justify-center items-center basis-full'>{tagSection}</div>;
    }

    if (primaryAction === null || !primaryFieldName) {
      console.warn('%c Generic tag render required primaryAction and primaryAction.name', 'font-size:14px;color:red');
      return <></>;
    }

    const renderTag = (tagSection as TagSection)?.renderTag;
    const clearTag = (tagSection as TagSection)?.onClear;

    const orderedKeys = keys(watchPrimaryFields).sort((a, b) => {
      const indexA = defaultOrderDict[a] ?? 0;
      const indexB = defaultOrderDict[b] ?? 0;
      return indexA - indexB;
    });
    const fieldKeyHasValue = orderedKeys
      .filter((key) => {
        return typeof renderTag !== 'function' || renderTag(key, watchPrimaryFields[key]) !== null;
      })
      .filter((key) => !DataViewer.isEmpty(watchPrimaryFields[key]));

    return !loading ? (
      <div className={classNames('flex justify-start items-center basis-full', fieldKeyHasValue?.length && 'pt-[10px]')}>
        {fieldKeyHasValue.map((key) => (
          <Tag
            key={key}
            closable
            className='bg-gray2 body-400 tag-item flex items-center'
            onClose={(e) => {
              e.preventDefault();
              if (clearTag) {
                clearTag({ [key]: watchPrimaryFields[key] });
              } else if (primaryFieldName) {
                methods.setValue(primaryFieldName, { ...watchPrimaryFields, [key]: undefined });
              }
              methods.handleSubmit(onSubmit)();
            }}
          >
            {typeof renderTag !== 'function' ? watchPrimaryFields[key] : renderTag(key, watchPrimaryFields[key])}
          </Tag>
        ))}
        {fieldKeyHasValue.length ? (
          <Button
            size='small'
            className='tag-btn__clear-all'
            onClick={(e) => {
              e.preventDefault();
              if (clearTag) {
                clearTag(null);
              } else if (primaryFieldName) {
                let resetValues;
                if (watchPrimaryFields instanceof Array) {
                  resetValues = [];
                } else if (watchPrimaryFields instanceof Object) {
                  resetValues = {};
                }
                methods.setValue(primaryFieldName, resetValues);
                methods.handleSubmit(onSubmit)();
              }
            }}
          >
            {t('button:clear_filter')}
          </Button>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className='base-filter-wrapper flex justify-between flex-wrap'>
      {generateSearchBoxSection()}
      {generateActionGroup()}
      {generateFilterTags()}
    </div>
  );
};

/**
 * This component required react-hook-form and wrapped by FormProvider.
 * @version 0.1.1
 *
 * @param searchBox - U can choose with my pattern or a React Component
 * @param primaryAction - U can choose with my pattern or a React Component
 * @param secondaryAction - U can choose with my pattern or a React Component
 * @param tagSection - U can choose with my pattern or a React Component
 * @param onSubmit - callback when submit filter. U can handle it with useForm().handleSubmit at component wrapped this Filter.
 *
 * @example
 * export default function App() {
 * const methods = useForm()
 * const applyFilter = (data) => console.log(data)
 *
 * const subMethods = useForm({defaultValues:{text1: 'text1', text2: 'text2'}});
 * const submitPrimaryFilters = (values: FieldValues) => {
 *   filterForm.setValue('primaryFilters', { ...values} );
 *   filterForm.handleSubmit(applyFilter)();
 * };
 * return <FormProvider {...methods}>
 *    <FormBaseFilter
 *        onSubmit={applyFilter}
 *        searchBox={{ placeholder: 'search anything', name:'searchValue' }}
 *        primaryAction={{
 *          label: 'button:filter',
 *          name: 'primaryFilters',
 *          popoverContent: <FormProvider {...subMethods}>
 *              <div className='p-3'>
 *                  <input
 *                      className='my-3'
 *                      type='text'
 *                      {...subMethods.register('text1')}
 *                  />
 *                  <input
 *                      className='my-3'
 *                      type='text'
 *                      {...subMethods.register('text2')}
 *                  />
 *              </div>
 *              <BaseButton
 *                  className='mx-auto cursor-pointer'
 *                  onClick={subMethods.handleSubmit(submitPrimaryFilters)}>
 *                    submit
 *               </BaseButton>
 *            </FormProvider>,
 *            totalFiltered: 2
 *        }}
 *        secondaryAction={{
 *            label: 'project:second_filter:my_project',
 *            name: 'secondaryFilters',
 *            options: [
 *                { label: 'option1', value: 1 },
 *                { label: 'option2', value: 2 }
 *            ]
 *         }}
 *    />
 *  </FormProvider>;
 * };
 */
const FormBaseFilter = (props: IFormBaseFilterProps) => {
  const {
    defaultOrderTagSection = [
      'category',
      'countryIds',
      'formId',
      'categoryId',
      'patternId',
      'picIds',
      'companyId',
      'process',
      'status',
      'departureFrom',
      'departureTo',
      'createDateFrom',
      'createDateTo',
      'updatedDateFrom',
      'updatedDateTo'
    ],
    ...rest
  } = props;
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Filter defaultOrderTagSection={defaultOrderTagSection} {...rest} />
    </ErrorBoundary>
  );
};

export default FormBaseFilter;
