import { Tag } from 'antd';
import { t } from 'i18next';

interface ITaskProperty {
  label: string;
  color: string;
}

const TagsByTagType: { [key: string]: ITaskProperty } = {
  MOT: {
    label: 'MOT',
    color: '#525A68'
  },
  CS: {
    label: 'CS',
    color: '#F2A227'
  },
  OTHER: {
    label: t('template_layout:other_category'),
    color: '#076CE3'
  },
  SUPPORT: {
    label: t('task:type_support'),
    color: '#0BC599'
  }
} as const;

export interface ITagProps {
  type?: string;
  className?: string;
}

export const TagTaskType = ({ type, className }: ITagProps) => {
  const obj = type ? TagsByTagType[type] : undefined;

  return obj ? (
    <Tag color={obj.color} className={className}>
      {obj.label}
    </Tag>
  ) : (
    <span>{type}</span>
  );
};
