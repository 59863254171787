import { PlusCircleOutlined } from '@ant-design/icons';
import { DragEndEvent } from '@dnd-kit/core';
import { DefaultOptionType } from 'antd/es/select';
import type { ColumnsType } from 'antd/es/table';
import { debounce } from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import BasicFormEditor from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { BaseButton } from '@components/base-button/BaseButton';
import { FormInputSelect } from '@components/form-input-select/FormInputSelect';

import { MAX_LENGTH_TABLE } from '@utils/constants/AppConstants';
import { arraySwapByIndex, findIndexByKey, sanitizeAndRemoveInsecureLinks } from '@utils/helpers/globalHelper';
import { IMaterial, ITask } from '@utils/interfaces/template';
import { handleOrder } from '@utils/method';

import { IconAddNew, TrashBlackIcon } from '../../../../../components/icon-svg/IconSvg';
import BaseTable, { DragHandle } from '../../../../../components/table/BaseTable';
import { SUBMISSION_TO_MOT } from '../../AddEditTemplateLayout';

import './ReferenceMaterial.scss';

interface IReferenceMaterialProps {
  name: 'materials';
  control: Control;
  documents?: ITask[];
  documentOptions: DefaultOptionType[];
  formMethod?: any;
  loadingData: boolean;
  refreshSearchWhenOpen: boolean;
  onPopupScroll: () => void;
  onHandleSearchDocuments: (keyword: string) => void;
  disabled?: boolean;
}

const ReferenceMaterial = forwardRef((props: IReferenceMaterialProps, ref: any) => {
  const {
    name,
    control,
    documents,
    formMethod,
    loadingData,
    refreshSearchWhenOpen,
    documentOptions,
    onPopupScroll,
    onHandleSearchDocuments,
    disabled
  } = props;
  const [t] = useTranslation(); // declare i18n variable
  const { fields, append, insert, update, remove, replace } = useFieldArray({
    name,
    control,
    keyName: 'key',
    rules: {
      maxLength: MAX_LENGTH_TABLE
    }
  });
  const refRequire = useRef<HTMLDivElement>(ref);
  const refTable = useRef<any>(null);
  const submissionOptions: DefaultOptionType[] = [
    { label: t('template_layout:submission_need'), value: SUBMISSION_TO_MOT.NEED },
    { label: t('template_layout:Unnecessary'), value: SUBMISSION_TO_MOT.UNNEED }
  ];
  /**
   * Declare state
   */
  const [showAddLine, setShowAddLine] = useState<boolean>(true);
  const [, setRowSelected] = useState<IMaterial[]>([]);

  const onChangeDocuments = (value: any, index: number) => {
    const document: any = documents?.find((d: any) => d.id === value);
    if (document) {
      update(index, {
        ...fields[index],
        typeId: document?.id || '',
        typeName: document?.name || '',
        note: sanitizeAndRemoveInsecureLinks(document?.note || ''),
        countryId: document?.countryId || '',
        isEdit: true,
        submission: null,
        managementCode: document?.managementCode,
        numOfCopy: document?.numOfCopy,
        changeDocumentFlg: true
      });
    } else {
      update(index, {
        ...fields[index],
        typeId: null,
        typeName: '',
        isEdit: true,
        note: '',
        numOfCopy: null,
        submission: null,
        changeDocumentFlg: true
      });
    }
  };

  const onClearDocuments = (index: number) => {
    update(index, {
      ...formMethod.getValues(`${name}[${index}]`),
      typeId: null,
      typeName: '',
      numOfCopy: '',
      note: '',
      submission: null,
      isEdit: true,
      changeDocumentFlg: true
    });
  };

  // Table config
  const generateColumns = (): ColumnsType<IMaterial> => {
    const { error: referenceErrors } = formMethod.getFieldState(name);
    const spaceWithError = (i: number, key?: string) => {
      const rowError = referenceErrors?.[i];
      const isFieldError = key && rowError?.[key];
      if (isFieldError) return 'mt-[34px]';
      if (!isFieldError && rowError) return 'py-[8px]';
      return 'py-[8px]';
    };
    const hiddenColumn = {
      title: ' ',
      dataIndex: 'hidden',
      width: 0,
      className: 'hidden-column'
    };

    const orderColumn = {
      title: 'NO',
      dataIndex: 'no',
      width: 50,
      className: 'text-left',
      render: (_: string, record: ITask, index: number) => {
        return (
          <p className='flex items-center'>
            <DragHandle />
            <span className='ml-1'>{handleOrder(record) || index + 1}</span>
          </p>
        );
      }
    };

    const referenceWidth = 440;
    const referenceColumn = {
      title: t('reference_material:reference_material').toUpperCase(),
      dataIndex: 'typeId',
      key: 'typeId',
      width: referenceWidth,
      className: `max-w-[${referenceWidth}px] [&:not(th)]:align-middle`,
      render: (_: string, record: IMaterial, index: number) => {
        return (
          <div className={spaceWithError(index, 'typeId')}>
            <FormInputSelect
              searchMaxLength={128}
              name={`${name}[${index}].typeId`}
              id={`${name}[${index}].typeId`}
              control={control}
              options={documentOptions}
              optionLabelProp='name'
              key={record.id}
              onChange={(val: any) => {
                onChangeDocuments(val, index);
              }}
              onSelect={(val: any) => {
                if (val && val === fields[index]?.typeId) {
                  update(index, { ...fields[index], changeDocumentFlg: true });
                }
              }}
              onClear={() => onClearDocuments(index)}
              refreshSearchWhenOpen={refreshSearchWhenOpen}
              placeholder={`${t('reference_material:reference_material_placeholder')}`}
              handleSearchByApi={onHandleSearchDocuments}
              onPopupScroll={
                !loadingData
                  ? async (e: any) => {
                      const { target } = e;
                      if (target.scrollTop > 250 && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                        onPopupScroll();
                      }
                    }
                  : undefined
              }
              selectedItemDisplay={
                record?.changeDocumentFlg
                  ? undefined
                  : (val, options) => {
                      return record?.typeId === val
                        ? record?.documentName
                        : options instanceof Array
                          ? options.map((i) => i.label).join(',')
                          : options?.label;
                    }
              }
            />
          </div>
        );
      }
    };

    const numOfCopyWidth = 210;
    const numOfCopyColumn = {
      title: t('template_layout:number_of_copies').toUpperCase(),
      dataIndex: 'numOfCopy',
      key: 'numOfCopy',
      sorter: false,
      width: numOfCopyWidth,
      className: `max-w-[${numOfCopyWidth}px]`,
      render: (value: string | null, record: IMaterial, index: number) => (
        <div className={spaceWithError(index, 'numOfCopy')}>
          <FormInput
            className='!w-full '
            name={`${name}[${index}].numOfCopy`}
            defaultValue={value ?? ''}
            onChange={(val) => {
              update(index, { ...fields[index], numOfCopy: val });
            }}
            placeholder={t('placeholder:text_box', { field: t('template_layout:number_of_copies') }) ?? ''}
          />
        </div>
      )
    };

    const noteWidth = 594;
    const noteColumn = {
      title: t('template_layout:notes').toUpperCase(),
      dataIndex: 'note',
      key: 'note',
      sorter: false,
      width: noteWidth,
      className: `max-w-[500px] input-editor [&:not(th)]:align-middle`,
      render: (value: string | null, record: IMaterial, index: number) => (
        <div id={`${name}[${index}].note`} className={spaceWithError(index)}>
          <BasicFormEditor
            className='!w-full'
            name={`${name}[${index}].note`}
            control={control}
            defaultValue={formMethod.getValues(`${name}[${index}].note`) || undefined}
            placeholder={t('placeholder:text_box', { field: t('template_layout:notes') }) ?? ''}
          />
        </div>
      )
    };

    const submissionWidth = 140;
    const actionWidth = 50;

    const submissionAndActionColumn = {
      title: t('template_layout:submission').toUpperCase(),
      key: '',
      dataIndex: '',
      children: [
        {
          title: '',
          key: 'submission',
          dataIndex: 'submission',
          className: `max-w-[${submissionWidth}px] [&:not(th)]:align-middle`,
          width: submissionWidth,
          render: (_: any, record: IMaterial, index: number) => (
            <div className={spaceWithError(index, 'submission')}>
              <FormInputSelect
                name={`${name}.${index}.submission`}
                id={`${name}.${index}.submission`}
                control={control}
                options={submissionOptions}
                key={record.id}
                placeholder={`${t('notification:mark_as')}`}
              />
            </div>
          )
        },
        {
          title: ' ',
          key: 'media',
          width: actionWidth,
          className: `max-w-[${actionWidth}px]`,
          render: (_: any, record: IMaterial, index: number) => (
            <div>
              <span onClick={() => remove(index)}>
                <TrashBlackIcon />
              </span>
              {showAddLine && (
                <div className={` ${showAddLine ? 'add-icon-container' : 'add-icon-disabled'}`}>
                  <PlusCircleOutlined onClick={() => handleAdd(index)} />
                </div>
              )}
            </div>
          )
        }
      ]
    };

    return [hiddenColumn, orderColumn, referenceColumn, numOfCopyColumn, noteColumn, submissionAndActionColumn];
  };

  /**
   * Methods
   */
  // handle row checked
  const handleRowCheck = (selectedRowKeys: React.Key[], selectedRows: IMaterial[]) => {
    setRowSelected(selectedRows);
    return {
      selectedRowKeys,
      selectedRows
    };
  };

  const addNewLine = (index: number, values: any[]) => {
    if (values.length < 50) {
      const newRow = {
        key: uuidv4(),
        typeId: null,
        description: null,
        media: null,
        submission: null,
        note: '',
        numOfCopy: '',
        changeDocumentsFlg: true
      };
      insert(index + 1, newRow);
    }
  };

  const debouncedAddNewLine = debounce(async (index) => {
    const values = formMethod?.getValues(name) || [];
    addNewLine(index, values);
  }, 300);

  useEffect(() => {
    if (fields.length < MAX_LENGTH_TABLE) {
      setShowAddLine(true);
    } else {
      setShowAddLine(false);
    }
  }, [fields.length]);

  // insert new record between two specific rows
  const handleAdd = (index: number) => {
    debouncedAddNewLine(index);
  };

  // append new record
  const handleAddRow = () => {
    const values = formMethod?.getValues(name) || [];
    if (values.length < 50) {
      const newRow: IMaterial = {
        key: uuidv4(),
        typeId: null,
        description: null,
        media: null,
        submission: null,
        note: '',
        numOfCopy: '',
        changeDocumentFlg: true
      };
      append(newRow);
    }
  };

  // delete row
  const handleDeleteRow = (keys: React.Key[]) => {
    const deletedItems = [];
    const removeIndexes = fields.reduce((rmIndexes: any, field: any, index: number) => {
      if (keys.includes(field.key) && !field?.id?.includes('new-document')) {
        deletedItems.push({ id: field?.id, version: field.version });
        rmIndexes.push(index);
      }
      return rmIndexes;
    }, []);
    remove(removeIndexes);
    refTable.current?.clearSelected();
  };

  // Empty Table data
  const getEmptyDataAlert = (): React.ReactNode => {
    return (
      <div className='text-center'>
        <p className='body-400'>{t('template_layout:emty_table')}</p>
      </div>
    );
  };
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const values = formMethod.getValues('materials');
      const activeIndex = findIndexByKey(fields, active?.id as string);
      const overIndex = findIndexByKey(fields, over?.id as string);
      const newList = arraySwapByIndex(values, activeIndex, overIndex);
      replace(newList);
    }
  };
  // render
  return (
    <div id='reference-material' className='add-edit-template-reference-material' ref={refRequire}>
      <BaseTable
        ref={refTable}
        className='base-table-bordered w-full'
        classEmptyCustom='!h-[38px] !py-[0px]'
        rowKey={'key'}
        columns={generateColumns()}
        dataSource={fields}
        onSelected={handleRowCheck}
        showAddLine={showAddLine}
        pagination={false}
        isShowDelete
        handleDeleteClick={handleDeleteRow}
        emptyDataAlert={getEmptyDataAlert()}
        onRowAble={true}
        rowClassName={(record, index) => {
          return `materials-row-${index}`;
        }}
        onDragEnd={onDragEnd}
      />
      <BaseButton disabled={disabled || !showAddLine} onClick={handleAddRow} size='medium' className='mt-6' icon={<IconAddNew />} type='secondary'>
        {t('required_documents:add_line')}
      </BaseButton>
    </div>
  );
});

export default ReferenceMaterial;
