import { CATEGORY, RouterIdType, TASK_MOT_OTHER, TASK_TODO_SUPPORT } from '../constants';

export interface IPages {
  pageSize: number;
  pageIndex: number;
}

export interface IFilterConfigs<T> {
  filterValue: T;
  filterType: RouterIdType;
}

/**
 * Interface for the postal code (https://postcode.teraren.com/postcodes.json)
 */
interface ITerarenPostalCode {
  jis: string;
  old: string;
  new: string;
  prefecture_kana: string;
  city_kana: string;
  suburb_kana: string;
  prefecture: string;
  prefecture_roman: string;
  city: string;
  city_roman: string;
  suburb_roman: string;
  suburb: string;
  is_separated_suburb: number;
  is_koaza: number;
  is_chome: number;
  is_include_area: number;
  status: number;
  reason: number;
  office: string | null;
  office_kana: string | null;
  office_roman: string | null;
  handling_post_office: string | null;
  post_type: string | null;
  multiple_numbers: string | null;
  url: string;
  street_address: string | null;
}

export interface IPostalCode extends Partial<ITerarenPostalCode> {}

/**
 * @description Interface for the postal code (https://jp-postal-code-api.ttskch.com)
 * @requires we use new service for postal code. So need migration from this response to IPostalCode at
 * @link src\hooks\usePostalCode.ts
 */
export interface IJPPostalCode {
  postalCode: string;
  addresses: IAddressEntry[];
}

export interface IAddress {
  prefecture?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
}

export interface IAddressEntry {
  prefectureCode: string;
  ja?: IAddress;
  kana?: IAddress;
  en?: IAddress;
}

export type TODO_SUPPORT_TASK_TYPE = (typeof TASK_TODO_SUPPORT)[keyof typeof TASK_TODO_SUPPORT];
export const TODO_SUPPORT_GROUPS_OPTION = [CATEGORY.CS, CATEGORY.SUPPORT];

export type MOT_OTHER_TASK_TYPE = (typeof TASK_MOT_OTHER)[keyof typeof TASK_MOT_OTHER];
export const MOT_OTHER_GROUPS_OPTION = [CATEGORY.MOT, CATEGORY.OTHER];
