import { PlusCircleOutlined } from '@ant-design/icons';
import { DragEndEvent } from '@dnd-kit/core';
import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ITemplateProcess } from '@/pages/template-management/view/modules';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';
import { BaseButton } from '@/components/base-button/BaseButton';
import BasicFormEditor from '@/components/form-box-editor';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { IconAddNew, TrashBlackIcon } from '@/components/icon-svg/IconSvg';
import BaseTable, { DragHandle } from '@/components/table/BaseTable';

import { MAX_LENGTH_TABLE } from '@/utils/constants/AppConstants';
import { _guuid, arraySwapByIndex, findIndexByKey } from '@/utils/helpers/globalHelper';
import { IDocument, ITask } from '@/utils/interfaces/template';
import { handleClassList } from '@/utils/method';
import { CATEGORY_OPTIONS } from '@utils/constants';

import './RequiredDocuments.scss';

interface ITaskTableProps {
  name: string;
  documents?: ITask[];
  documentOptions: DefaultOptionType[];
  formMethod: UseFormReturn<any, any>;
  loadingData: boolean;
  onPopupScroll: () => void;
  onHandleSearchDocuments: (keyword: string) => void;
  parentIndex: number;
  showAddLine?: boolean;
  hideAddButton?: boolean;
  processItem: ITemplateProcess;
}

const TaskTable = ({
  name,
  documentOptions,
  documents,
  loadingData,
  onPopupScroll,
  parentIndex,
  showAddLine = true,
  hideAddButton,
  processItem,
  formMethod
}: ITaskTableProps) => {
  const [t] = useTranslation();
  const { fields, update, remove, append, insert, replace } = useFieldArray({
    name,
    control: formMethod.control,
    keyName: 'key',
    rules: {
      maxLength: MAX_LENGTH_TABLE
    }
  });

  const showAddLineTable = showAddLine && fields.length < MAX_LENGTH_TABLE;

  const refTable = useRef<any>(null);
  /**
   * Declare state
   */

  const optionTaskId = useCallback(
    (taskType?: string) => {
      const options = documentOptions.filter((item) => item?.type === taskType);
      return options || [];
    },
    [documentOptions]
  );

  const getTaskInfo = useCallback(
    (taskId?: string) => {
      return documents?.find((x: any) => x.id === taskId);
    },
    [documents]
  );

  const generateColumns = () => {
    const { error: taskErrors } = formMethod.getFieldState(`${name}`) as any;
    const spaceWithError = (i: number, key?: string) => {
      const rowError = taskErrors?.[i];
      const isFieldError = key && rowError?.[key];
      if (isFieldError) return 'taskId' === key ? 'mt-[30px]' : 'mt-[26px]';
      return '';
    };
    return [
      { key: 'sort', width: 32, className: '!pr-0', render: () => <DragHandle /> },
      {
        title: t('template_layout:category').toUpperCase(),
        dataIndex: 'taskType',
        key: 'taskType',
        width: 220,
        className: 'align-middle',
        render: (_: any, record: any, index: number) => {
          return (
            <div className={classNames('max-w-[196px]', spaceWithError(index, 'taskType'))}>
              <FormInputSelect
                name={`${name}.${index}.taskType`}
                id={`${name}.${index}.taskType`}
                control={formMethod.control}
                options={CATEGORY_OPTIONS}
                onChange={(val) => {
                  update(index, { ...fields[index], taskType: val, taskId: null, detail: '' });
                }}
                className='w-full'
                key={record.rowKey}
                placeholder={t('placeholder:select', { field: t('template_layout:category') }) as string}
                tabIndex={0}
              />
            </div>
          );
        }
      },
      {
        title: t('template_layout:title').toUpperCase(),
        key: 'taskId',
        dataIndex: 'taskId',
        width: 320,
        className: 'min-w-[250px] max-w-[250px] align-middle',
        render: (_: string, record: any, index: number) => {
          const optionsTask = optionTaskId(record?.taskType).map((o) => ({
            ...o,
            label: o.label
          }));
          return (
            <div className={spaceWithError(index, 'taskId')}>
              <FormInputSelect
                optionLabelProp='renderValue'
                className='w-full'
                name={`${name}.${index}.taskId`}
                id={`${name}.${index}.taskId`}
                control={formMethod.control}
                options={optionsTask}
                key={record.rowKey}
                disabled={!record?.taskType}
                onChange={(value: any) => {
                  if (!value) {
                    update(index, { ...fields[index], taskId: null, detail: '', changeTaskFlg: true });
                    return;
                  }
                  const taskInfo: any = getTaskInfo(value);
                  if (taskInfo) {
                    update(index, {
                      ...fields[index],
                      taskId: value,
                      taskType: taskInfo.type,
                      detail: taskInfo.content,
                      taskName: taskInfo.name,
                      managementCode: taskInfo.managementCode,
                      countryId: taskInfo.countryId,
                      countryName: taskInfo.countryName,
                      displayOrder: index + 1,
                      changeTaskFlg: true
                    });
                    taskInfo.content && formMethod.clearErrors(`documents.[${parentIndex}].templateProcessTasks.[${index}].detail`);
                  }
                }}
                onSelect={(val: any) => {
                  if (val && val === (fields?.[index] as unknown as any)?.taskId) {
                    update(index, { ...fields[index], changeTaskFlg: true });
                  }
                }}
                placeholder={t('placeholder:select', { field: t('template_layout:title') }) as string}
                loading={loadingData}
                onPopupScroll={
                  !loadingData
                    ? async (e: any) => {
                        const { target } = e;
                        if (target.scrollTop > 250 && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                          onPopupScroll();
                        }
                      }
                    : undefined
                }
                tabIndex={0}
                selectedItemDisplay={
                  record?.changeTaskFlg
                    ? undefined
                    : (val, options) => {
                        return record?.taskId === val
                          ? record?.taskName
                          : options instanceof Array
                            ? options.map((i) => i.label).join(',')
                            : options?.label;
                      }
                }
              />
            </div>
          );
        }
      },
      {
        title: t('project:template_layout:task_content').toUpperCase(),
        key: 'createdName',
        className: 'min-w-[250px] max-w-[250px] input-editor align-middle !pr-0',
        render: (value: any, record: any, index: number) => {
          return (
            <div id={`${name}.${index}.detail`} className={spaceWithError(index)}>
              <BasicFormEditor
                disabled={!record.taskType}
                className='!w-full'
                name={`${name}.${index}.detail`}
                defaultValue={record?.detail}
                control={formMethod.control}
                placeholder={t('project:template_layout:task_detail_placeholder') || ''}
                editorWrapperProps={{
                  className: '[&>.ql-toolbar>.ql-formats]:flex'
                }}
              />
            </div>
          );
        }
      },
      {
        title: ' ',
        key: 'media',
        width: 45,
        className: 'max-w-[50px]',
        render: (_: any, record: IDocument, index: number) => (
          <div>
            <div className='flex items-center justify-center' onClick={() => handleDeleteRow([record.key as string])}>
              <TrashBlackIcon
                width={18}
                height={18}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleDeleteRow([record.key as string]);
                  }
                }}
              />
            </div>
            {showAddLineTable && (
              <div className={` ${showAddLine ? 'add-icon-container' : 'add-icon-disabled'}`}>
                <PlusCircleOutlined
                  onClick={() => handleAdd(index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAdd(index);
                    }
                  }}
                />
              </div>
            )}
          </div>
        )
      }
    ];
  };

  const handleRowCheck = (selectedRowKeys: React.Key[], selectedRows: ITask[]) => {
    return {
      selectedRowKeys,
      selectedRows
    };
  };

  useEffect(() => {
    handleClassList();
  }, []);

  const debouncedAddNewLine = debounce(async (index) => {
    if (fields?.length < 50) {
      const newRow: any = {
        rowKey: _guuid(),
        templateProcessId: processItem.id,
        taskType: null,
        taskId: undefined,
        detail: '',
        taskName: '',
        key: _guuid(),
        changeTaskFlg: true
      };
      insert(index + 1, newRow);
    }
  }, 300);

  // Append new record between two specific rows
  const handleAdd = (index: number) => {
    debouncedAddNewLine(index);
  };

  // handleAddRow
  const handleAddRow = () => {
    const newTask: any = {
      rowKey: _guuid(),
      templateProcessId: processItem.id,
      taskType: null,
      taskId: undefined,
      detail: '',
      taskName: '',
      key: _guuid()
    };
    append(newTask);
  };

  // delete row
  const handleDeleteRow = (deletedKeys: React.Key[]) => {
    const deletedIndexes = (fields ?? []).reduce((indexes: number[], field, index) => {
      if (deletedKeys.includes(field.key)) {
        indexes.push(index);
      }
      return indexes;
    }, []);
    deletedIndexes.length && remove(deletedIndexes);
  };

  // Empty Table data
  const getEmptyDataAlert = (): React.ReactNode => {
    return <EmptyTextData className='text-center' text={'template_layout:emty_table'} />;
  };

  const handleRowClass = (record: IDocument, index: number) => {
    let className: string = `document-row-${index}`;
    return className;
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = findIndexByKey(fields, active?.id as string);
      const overIndex = findIndexByKey(fields, over?.id as string);
      const obj = fields.find((record) => record.key === active?.id);
      const newDocuments = (formMethod?.getValues('documents') ?? []).map((item: any) => {
        let templateProcessTasks = item.templateProcessTasks;
        if (item.id === (obj as any).templateProcessId) {
          templateProcessTasks = arraySwapByIndex(templateProcessTasks, activeIndex, overIndex);
          replace(templateProcessTasks);
        }
        return {
          ...item,
          templateProcessTasks
        };
      });
      formMethod?.setValue('documents', newDocuments);
    }
  };

  return (
    <div className='table-items pl-[40px]'>
      <div className='table-items-collapse'></div>
      <div className='flex-1 table-items-table'>
        {fields.length ? (
          <BaseTable
            ref={refTable}
            className='base-table-bordered'
            columns={generateColumns()}
            dataSource={fields}
            onSelected={handleRowCheck}
            pagination={false}
            showAddLine={showAddLineTable}
            isShowDelete
            showHeader={false}
            handleDeleteClick={(keys) => handleDeleteRow(keys)}
            emptyDataAlert={<></>}
            onRowAble={true}
            rowClassName={(record, index) => handleRowClass(record, index)}
            onDragEnd={onDragEnd}
          />
        ) : (
          getEmptyDataAlert()
        )}
        {!hideAddButton && (
          <BaseButton
            disabled={fields.length >= MAX_LENGTH_TABLE}
            onClick={() => handleAddRow()}
            size='medium'
            className='medium-secondary mt-3'
            icon={<IconAddNew />}
            type='secondary'
          >
            {t('required_documents:add_line')}
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default TaskTable;
